import React, { isValidElement } from 'react'
import kebabCase from 'lodash/kebabCase'
import isFunction from 'lodash/isFunction'

import { Flex, Box, Text, Icon } from 'components'

import { routes as channels } from './routes'
import { useRouter } from './Router'

const Menu = ({ title, onClose }) => {
  const { navigate } = useRouter()

  return (
    <>
      {channels.map((c) => {
        const {
          icon,
          title,
          description,
          route,
          condition,
          onClick,
          eventName,
        } = c
        if (isFunction(condition) && !condition()) {
          return null
        }

        return (
          <Flex
            key={kebabCase(title)}
            onClick={() => {
              if (onClick) {
                onClick()
              } else {
                navigate(route)
              }
              eventName &&
                window.dataLayer &&
                window.dataLayer.push({
                  event: eventName,
                })
            }}
            sx={{
              px: 2,
              py: 3,
              borderBottom: '1px solid',
              borderBottomColor: 'colorBlue10',
              cursor: 'pointer',
              '&:hover': {
                bg: 'colorBlue2',
              },
            }}
            id={title}
          >
            <Box flexGrow="0" px={2} py={1}>
              <Icon source={icon} size={18} />
            </Box>
            <Box flexGrow="1" px={2}>
              <Text fontSize={1}>{title}</Text>
              {isValidElement(description) ? (
                description
              ) : (
                <Text fontSize={0} color="muted">
                  {description}
                </Text>
              )}
            </Box>
          </Flex>
        )
      })}
    </>
  )
}

export default Menu
