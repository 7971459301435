import React, { useContext, useState } from 'react'
import find from 'lodash/find'

import { routes } from './routes'

const RouterContext = React.createContext({})

const RouterProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState('/')
  const navigate = (route) => setCurrentRoute(route)

  return (
    <RouterContext.Provider
      value={{
        route: find(routes, { route: currentRoute }),
        navigate,
      }}
    >
      {children}
    </RouterContext.Provider>
  )
}

const useRouter = () => {
  const context = useContext(RouterContext)

  if (context === undefined) {
    throw new Error('useRouter must be used within a RouterProvider')
  }

  return context
}

export { RouterContext, RouterProvider, useRouter }
