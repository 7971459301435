import React from 'react'

import { Box } from 'components'

import Header from './Header'
import Footer from './Footer'
import Menu from './Menu'
import { RouterProvider, useRouter } from './Router'

const Routes = () => {
  const { route } = useRouter()

  if (!!!route) {
    return <Menu />
  }

  return <route.component />
}

const ConnectedWidget = React.forwardRef(({ onClose }, ref) => {
  return (
    <RouterProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          bg: 'colorBlue5',
          borderRadius: '3px 0 0 3px',
          boxShadow: 'md',
        }}
      >
        <Header onClose={onClose} />
        <Box ref={ref} sx={{ flexGrow: 1, overflow: 'auto', bg: 'white' }}>
          <Routes />
        </Box>
        <Footer />
      </Box>
    </RouterProvider>
  )
})

export default ConnectedWidget
