import React from 'react'

import { ChevronLeft, X as Close, Phone } from 'icons'

import { Flex, Box, Text, Icon } from 'components'
import { useRouter } from './Router'

const Header = ({ onClose }) => {
  const { route, navigate } = useRouter()

  const matchedRoute = !!route
  const title = matchedRoute ? route.title : 'Kontakt und Services'

  return (
    <Flex
      sx={{
        alignItems: 'center',
        bg: 'purple',
        boxShadow: 'md',
        borderRadius: 'default',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height: 40,
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          cursor: matchedRoute ? 'pointer' : 'inherit',
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          flexGrow: 0,
          pl: 3,
          pr: 2,
          lineHeight: 0,
        }}
        onClick={matchedRoute ? () => navigate('/') : null}
      >
        {matchedRoute ? (
          <Icon source={ChevronLeft} color="white" size={18} />
        ) : (
          <Icon source={Phone} color="white" size={18} />
        )}
      </Box>

      <Box flexGrow="1" py={2}>
        <Text fontSize={1} fontWeight="bold" px={2} color="white">
          {title}
        </Text>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          flexGrow: 0,
          cursor: 'pointer',
          pr: 3,
          pl: 2,
          lineHeight: 0,
        }}
        onClick={onClose}
      >
        <Icon source={Close} color="white" size={18} />
      </Box>
    </Flex>
  )
}

export default Header
